
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MTextField
  }
})
export default class Login extends Vue {
  email = "";
  enteredEmail = false;
  password = "";
  enteredPassword = false;

  get validEmail(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }

  get validPassword(): boolean {
    return this.password.length >= 8;
  }

  get valid() {
    return this.validEmail && this.validPassword;
  }

  get allEntered(): boolean {
    return this.enteredEmail && this.enteredPassword;
  }

  allEnter() {
    this.enteredEmail = true;
    this.enteredPassword = true;
  }

  signIn() {
    this.allEnter();

    if (!this.valid) {
      return;
    }

    store.dispatch("signIn", {
      email: this.email,
      password: this.password
    });
  }
}
